@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Archivo;
    src: url(../fonts/Archivo-ExtraBold.ttf) format("ttf");
  }
  /* @font-face {
    font-family: Archivo;
    font-weight: 400;
    src: url(../fonts/Archivo-Regular.ttf) format("ttf");
  } */
  html {
    font-family: Archivo, sans-serif;
    font-weight: 800;
    background-color: #fedb00;
  }
}
